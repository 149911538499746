import React from 'react'
import { Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Image1 from '../assets/images/image2.jpeg'
import Image2 from '../assets/images/image3.jpeg'
import Image3 from '../assets/images/image4.jpeg'
import Image4 from '../assets/images/image5.jpeg'
/**
 * Agency bio section
 */
export default function AgencyFeature() {
    const { t } = useTranslation()
    const imageStyle = { marginTop: 30, maxWidth: 500, width: '100%', height: 'auto' }
    return (
        <section className='section overflow-hidden bg-light'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-md-12'>
                        <div className='me-lg-5'>
                            {/* <div className='row justify-content-center'>
                                        <div className='col-12'>
                                            <div className='section-title text-center text-md-start mb-4 pb-2'>
                                                <h4 className='title mb-3'>Work Process</h4>
                                                <p className='para-desc text-muted mx-auto mb-0'>
                                                    Launch your campaign and benefit from our expertise on
                                                    designing and managing conversion centered bootstrap v5
                                                    html page.
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}

                            <Row>
                                <div className='col-md-12 mt-4 pt-2'>
                                    <div className='features feature-primary rounded border-0 d-flex'>
                                        <div className='content flex-1 ms-3'>
                                            <h4 className='title mb-3 text-center'>{t('section2Title')}</h4>
                                            <p className='text-muted mt-2 mb-0'>{t('section21')}</p>
                                        </div>
                                    </div>
                                    <img src={Image1} alt='' style={imageStyle} />
                                    <p className='text-muted mt-2 mb-0'>{t('section22')}</p>
                                    <img src={Image2} alt='' style={imageStyle} />
                                    <p className='text-muted mt-2 mb-0'>{t('section23')}</p>
                                    <img src={Image3} alt='' style={imageStyle} />
                                    <p className='text-muted mt-2 mb-0'>{t('section24')}</p>
                                    <img src={Image4} alt='' style={{}} />
                                    <p className='text-muted mt-2 mb-0'>{t('section25')}</p>
                                </div>

                                {/* <div className='col-md-12 mt-4 pt-2'>
                                            <div className='features feature-primary rounded border-0 d-flex'>
                                                <div className='feature-icon sm-icon text-center'>
                                                    <i className='rounded-pill'>2</i>
                                                </div>

                                                <div className='content flex-1 ms-3'>
                                                    <Link2 to='#' className='title h5 text-dark'>
                                                        Development
                                                    </Link2>
                                                    <p className='text-muted mt-2 mb-0'>
                                                        This prevents repetitive impairing the overall
                                                        facilitates the comparison.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-12 mt-4 pt-2'>
                                            <div className='features feature-primary rounded border-0 d-flex'>
                                                <div className='feature-icon sm-icon text-center'>
                                                    <i className='rounded-pill'>3</i>
                                                </div>

                                                <div className='content flex-1 ms-3'>
                                                    <Link2 to='#' className='title h5 text-dark'>
                                                        Live Demo
                                                    </Link2>
                                                    <p className='text-muted mt-2 mb-0'>
                                                        This prevents repetitive impairing the overall
                                                        facilitates the comparison.
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}
                            </Row>
                        </div>
                    </div>

                    {/* <div className='col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0'>
                                <div className='img-fluid-responsive position-relative'>
                                    <img src={Laptop} className='mx-auto d-block' alt='' />
                                </div>
                            </div> */}
                </div>
            </div>
        </section>
    )
}
