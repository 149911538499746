import React from 'react'
import { Col, Row, Container } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import MapImage from '../assets/images/map.png'
import DocImage from '../assets/images/doc.jpg'
import Video from '../assets/video/home-video.mp4'

/**
 * Agency bio section
 */
export default function AgencyFeature() {
    const { t } = useTranslation()

    return (
        <section className='section' id='about'>
            <Container>
                <div>
                    <video
                        controls
                        src={Video}
                        alt='video popup'
                        width='100%'
                        height='500'
                        style={{ objectFit: 'cover' }}
                    />
                </div>
            </Container>
        </section>
    )
}
