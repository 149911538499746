import React from 'react'
import { Col, Row, Container } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Image1 from '../assets/images/image2.jpeg'
import Image2 from '../assets/images/image3.jpeg'
import Image3 from '../assets/images/image4.jpeg'
import Image4 from '../assets/images/image5.jpeg'

export default function AgencyFeature() {
    const { t } = useTranslation()

    return (
        <section className='section'>
            <Container>
                <Row className='justify-content-center'>
                    <Col>
                        <div className='section-title text-center mb-4 pb-2'>
                            <h4 className='title mb-3'>{t('section1Title')}</h4>
                            <p className='text-muted mx-auto para-desc mb-0'>{t('section1Par')}</p>
                        </div>
                    </Col>
                </Row>
                <div className='row justify-content-center'>
                    <Col lg={12}>
                        <div className='rounded shadow px-4 py-5 bg-white'>
                            <Row>
                                <div className='col-lg-6 col-md-6 mt-4 pt-4 mt-sm-0 pt-sm-0'>
                                    <div className='d-flex features feature-primary'>
                                        <div className='feature-icon text-center'>•</div>
                                        <div className='flex-1 ms-3'>
                                            <p className='mt-0'>{t('section11')}</p>
                                            {/* <p className='text-muted mb-0'>
                                                        Horem ipsum dolor consectetuer Lorem simply dummy orem
                                                        commo.
                                                    </p> */}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 mt-4 pt-4 mt-sm-0 pt-sm-0'>
                                    <div className='d-flex features feature-primary'>
                                        <div className='feature-icon text-center'>•</div>
                                        <div className='flex-1 ms-3'>
                                            <p className='mt-0'>{t('section12')}</p>
                                            {/* <p className='text-muted mb-0'>
                                                        When an unknown printer took a galley of type and
                                                        scrambled it.
                                                    </p> */}
                                        </div>
                                    </div>
                                </div>

                                {/* <div className='col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0 pt-lg-0'>
                                            <div className='d-flex features feature-primary'>
                                                <div className='feature-icon text-center'>
                                                    <i className='uil uil-camera rounded h4'></i>
                                                </div>
                                                <div className='flex-1 ms-3'>
                                                    <h5 className='mt-0'>quick gesture</h5>
                                                    <p className='text-muted mb-0'>
                                                        It has survived not only five centuries but leap in
                                                        typesetting.
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}

                                <div className='col-lg-6 col-md-6 mt-4 pt-4 mt-sm-0 pt-sm-0'>
                                    <div className='d-flex features feature-primary'>
                                        <div className='feature-icon text-center'>• </div>
                                        <div className='flex-1 ms-3'>
                                            <p className='mt-0'>{t('section13')} </p>
                                            {/* <p className='text-muted mb-0'>
                                                        It was popularised with the release of Letraset sheets
                                                        sit amet.
                                                    </p> */}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 mt-4 pt-4 mt-sm-0 pt-sm-0'>
                                    <div className='d-flex features feature-primary'>
                                        <div className='feature-icon text-center'>• </div>
                                        <div className='flex-1 ms-3'>
                                            <p className='mt-0'>{t('section14')}</p>
                                            {/* <p className='text-muted mb-0'>
                                                        It is a long established fact that a reader will be
                                                        distracted.
                                                    </p> */}
                                        </div>
                                    </div>
                                </div>

                                {/* <div className='col-lg-4 col-md-6 mt-4 pt-4'>
                                            <div className='d-flex features feature-primary'>
                                                <div className='feature-icon text-center'>
                                                    <i className='mdi mdi-code-tags rounded h4'></i>
                                                </div>
                                                <div className='flex-1 ms-3'>
                                                    <h5 className='mt-0'>Healthcare Services</h5>
                                                    <p className='text-muted mb-0'>
                                                        A point of using lorem ipsum is that it has normal
                                                        distribution.
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}
                            </Row>
                        </div>
                    </Col>
                </div>
            </Container>
        </section>
    )
}
