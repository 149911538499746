import React from 'react'
import { Col, Row } from 'reactstrap'
import BackgroudImage from '../assets/images/bg/6.jpg'
import { useTranslation } from 'react-i18next'
import Image from '../assets/images/image1.jpeg'

/**
 * Agency feature section
 */
export default function AgencyFeature() {
    const { t } = useTranslation()
    const AgencyFeature = [
        {
            id: '1',
            icon: 'mdi mdi-language-php',
            title: t('q1'),
            description: t('a1'),
        },
        {
            id: '2',
            icon: 'mdi mdi-file-image',
            title: t('q2'),
            description: t('a2'),
        },
        {
            id: '3',
            icon: 'uil uil-camera',
            title: t('q3'),
            description: t('a3'),
        },
        {
            id: '4',
            icon: 'mdi mdi-google-glass',
            title: t('q4'),
            description: '',
            list: [t('a41'), t('a42'), t('a43'), t('a44'), t('a45')],
        },
        {
            id: '5',
            icon: 'mdi mdi-source-commit',
            title: t('q5'),
            description: t('a5'),
            list: [t('a51'), t('a52'), t('a53'), t('a54')],
            image: Image,
        },
        // {
        //     id: '6',
        //     icon: 'mdi mdi-source-commit',
        //     title: t('q6'),
        //     description: t('a6'),
        // },
        {
            id: '7',
            icon: 'mdi mdi-source-commit',
            title: t('q7'),
            description: t('a7'),
        },
        {
            id: '8',
            icon: 'mdi mdi-source-commit',
            title: t('q8'),
            description: t('a8'),
        },
    ]

    return (
        <>
            {/* Start Features  */}
            <section id='services'>
                <div className='container-fluid px-0'>
                    <Row className='g-0'>
                        {/* <Col lg={4} className='order-1 order-lg-2 py-5 py-lg-0'>
                            <img source={BackgroudImage} width='100%' height='100' />
                            <div className='py-5 py-lg-0 my-5 my-lg-0'></div> */}
                        {/* </Col> */}

                        <Col lg={12} className='order-2 order-lg-1'>
                            <div className='bg-light px-4 py-5 px-md-5'>
                                <div className='row'>
                                    <div className='col'>
                                        <div className='section-title mb-4 pb-2'>
                                            <h4 className='title text-center'>A.M.I. HAL / RAR System</h4>
                                            {/* <p className='text-muted para-desc mb-0'>
                                                Launch your campaign and benefit from our expertise on
                                                designing and managing conversion centered bootstrap v5 html
                                                page.
                                            </p> */}
                                        </div>
                                    </div>
                                </div>

                                <Row>
                                    {AgencyFeature.map((item, key) => (
                                        <>
                                            <Col md={6} className='mt-4 pt-2' key={key}>
                                                <div className='features feature-primary'>
                                                    <div className='feature-icon sm-icon text-center'>
                                                        <i className='rounded-pill'>{key + 1}</i>
                                                    </div>
                                                    <div className='flex-1 mt-4'>
                                                        <h5 className='mt-0'>{item.title}</h5>
                                                        {item.description && (
                                                            <p className='text-muted mb-0'>
                                                                {item.description}
                                                            </p>
                                                        )}
                                                        {item.list && (
                                                            <ul>
                                                                {item.list.map(desc => (
                                                                    <li>
                                                                        <p className='text-muted mb-0'>
                                                                            {desc}
                                                                        </p>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                            {item.image && (
                                                <Col
                                                    md={6}
                                                    className='mt-4 pt-2 d-flex justify-center align-items-center'
                                                    key={key}
                                                >
                                                    <div className='features feature-primary'>
                                                        <div className='flex-1 mt-4'>
                                                            <img
                                                                src={item.image}
                                                                style={{ width: '100%', height: 'auto' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                        </>
                                    ))}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            {/* End Features  */}
        </>
    )
}
