import React from 'react'
import { Col, Row, Container } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import MapImage from '../assets/images/map.png'
import DocImage from '../assets/images/doc.jpg'
/**
 * Agency bio section
 */
export default function AgencyFeature() {
    const { t } = useTranslation()

    return (
        <section className='section' id='about'>
            <Container>
                <div style={{ background: `url(${MapImage}) center center` }}>
                    <Row className='align-items-center'>
                        <Col lg={5} md={5}>
                            <div className='position-relative me-lg-5'>
                                <img src={DocImage} className='rounded img-fluid mx-auto d-block' alt='' />
                                {/* <div className='play-icon'>
                                            <Link2
                                                to='#'
                                                onClick={openModal}
                                                data-type='youtube'
                                                data-id='yba7hPeTSjk'
                                                className='play-btn lightbox'
                                            >
                                                <i className='mdi mdi-play text-primary rounded-circle bg-white shadow'></i>
                                            </Link2>
                                        </div> */}
                            </div>
                        </Col>

                        <Col lg={6} md={6} className='mt-4 pt-2 mt-sm-0 pt-sm-0'>
                            <div className='section-title'>
                                <h4 className='title mb-3'>{t('doctorBio')} </h4>
                                {/* <p className='text-muted'>
                                            This prevents repetitive patterns from impairing the overall
                                            visual impression and facilitates the comparison of different
                                            typefaces. Furthermore, it is advantageous when the dummy text is
                                            relatively realistic so that the layout impression of the final
                                            publication is not compromised.
                                        </p> */}
                                <ul className='list-unstyled text-muted'>
                                    <li className='mb-1'>
                                        <span className='text-primary h5 me-1 ms-1'>
                                            <i className='uil uil-check-circle align-middle'></i>
                                        </span>
                                        {t('bio1')}
                                    </li>
                                    <li className='mb-1'>
                                        <span className='text-primary h5 me-1 ms-1'>
                                            <i className='uil uil-check-circle align-middle'></i>
                                        </span>
                                        {t('bio2')}
                                    </li>
                                    <li className='mb-1'>
                                        <span className='text-primary h5 me-1 ms-1'>
                                            <i className='uil uil-check-circle align-middle'></i>
                                        </span>
                                        {t('bio3')}
                                    </li>
                                    <li className='mb-1'>
                                        <span className='text-primary h5 me-1 ms-1'>
                                            <i className='uil uil-check-circle align-middle'></i>
                                        </span>
                                        {t('bio4')}
                                    </li>
                                    <li className='mb-1'>
                                        <span className='text-primary h5 me-1 ms-1'>
                                            <i className='uil uil-check-circle align-middle'></i>
                                        </span>
                                        {t('bio5')}
                                    </li>
                                    <li className='mb-1'>
                                        <span className='text-primary h5 me-1 ms-1'>
                                            <i className='uil uil-check-circle align-middle'></i>
                                        </span>
                                        {t('bio6')}
                                    </li>
                                </ul>
                                {/* 
                                        <div className='d-inline-block'>
                                            <div className='pt-3 d-flex align-items-center border-top'>
                                                <i className='uil uil-envelope text-primary me-2 fs-1'></i>
                                                <div className='content'>
                                                    <p className='mb-0'>Need More Help?</p>
                                                    <Link2 to='#' className='text-dark h6'>
                                                        Ask us your question
                                                    </Link2>
                                                </div>
                                            </div>
                                        </div> */}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}
